body {
	font-size:14px;
	font-family: 'Roboto', sans-serif;
}

ul {
	margin:0;
	padding:0;
}

.top-icn {
	padding:6px 0px;
	border-bottom:solid 1px #ccc;
	background:#232323;
}

.left-top-icn {
	text-align:right;
}

.left-top-icn ul li {
	display:inline-block;
}

.left-top-icn ul li:nth-child(1) {
	border-right:solid 2px #ccc;
}

.current-menu {
    background-color: #3bceaf !important;
    color:white !important;
}

.left-top-icn ul li a {
	color:#fff;
	text-decoration:none;
	padding:0px 10px;
	font-weight:500;
}

.left-top-icn ul li a i {
	color: #fff;
    padding: 4px 0px;
    background: #3bceaf;
    border: solid 1px #3bceaf;
    margin: 0px 5px;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: inline-block;
    text-align: center;

}

.ryt-top-icn ul li {
	display:inline-block;
}

.ryt-top-icn ul li a {
    color: #fff;
    padding: 2px 0px;
    background: #3bceaf;
    border: solid 1px #3bceaf;
    margin: 0px 5px;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: block;
    text-align: center;
}

#navbar {
	background:#fff;
	z-index:999;
}

/* Page content */
.content {
  padding: 16px;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0 8px 6px -10px black;
	   -moz-box-shadow: 0 8px 6px -10px black;
	        box-shadow: 0 8px 6px -10px black;
}

.sticky img {
	width:50% !important;
	transition:.3s;
}

.sticky ul li {
	padding:0px 3px !important;
	font-size: 14px !important;
    font-weight: 300 !important;
}

.sticky ul li a {
	padding:5px 20px !important;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 60px;
}

.menu i {
	color:#3bceaf;
}

.menu img {
	width:60%;
}

.menu ul li {
	padding:0px 3px;
	font-size: 18px;
    font-weight: 300;
}

.menu ul li a {
	color:#000;
	padding:10px 20px !important;
	transition:.3s;
	border-radius:3px;
}

.menu ul li a:hover, .menu ul li.active a {
	background:#3bceaf;
	color:#fff;
	transition:.3s;
	text-decoration: none;
}

.contact {
	background:url(../img/contact.jpg) no-repeat;
	background-size:cover;
	min-height:400px;
}

.contact-head h1 {
	text-align:center;
	color:#fff;
	padding-top:150px;
	font-size:52px;
}

.contact-head p {
	color:#fff;
	font-size:36px;
	text-align:center;
}

/*what-we-do*/

.shape {
    text-align: center;
    margin-top: 50px;
    padding: 30px;
    transition: all 0.3s ease 0s
}

.shape:hover {
    box-shadow: 0 0 5px #B0B0B0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: all 0.3s ease 0s;
    background: #fff;
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    cursor: pointer;
}

.shape i {
	font-size:22px;
	background: #3bceaf;
    border: 1px solid #3bceaf;
	color:#fff;
	height:40px;
	width:40px;
	line-height:1.8;
}

.shape h5 {
    color: #484b54;
    font-size: 18px;
    font-weight: 700;
    margin: 25px 0;
}

.shape p {
    color: #74777f;
    line-height: 1.8;
    font-weight: 300;
}

.brd {
	border-top:solid 1px #ccc;
}

.frm-cnt p {
	text-align:center;
	font-size:18px;
	padding:50px 0px;
	font-weight: 300;
}

.frm-cnt p span {
	font-weight:500;
}

.contact-frm a {
	text-decoration:none;
}

.contact-frm input {
	outline: 0;
    resize: none;
    font-weight: 400;
    width: 48%;
    line-height: 24px;
    height: 50px;
    font-size: 14px;
    border: 1px solid #f1f1f1;
    padding: 13px 20px;
    background: #f5f5fb;
    color: #323232;
    border-radius: 0px;
	margin:0px 5px;
    margin-bottom: 30px;
}

.contact-frm textarea{
	outline: 0;
    font-weight: 400;
    display: block;
    width: 100%;
    font-size: 14px;
    border: 1px solid #f1f1f1;
    padding: 13px 20px;
    background: #f5f5fb;
    color: #323232;
    border-radius: 0px;
    margin-bottom: 30px;
    box-shadow: none !important;
}

.frm-img img {
	border:solid 3px #ccc;
	transition: all 0.4s ease-in-out;
}

.frm-img img:hover {
	opacity:0.7;
	cursor:pointer;
}

.btn-one {
    -webkit-box-shadow: -5px 10px 30px -5px rgba(0, 0, 0, 0.2);
    box-shadow: -5px 10px 30px -5px rgba(0, 0, 0, 0.2);
    background: #3bceaf;
    color: #ffffff;
	cursor: pointer;
    display: inline-block;
    position: relative;
    z-index: 2;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    padding: 0 5px;
    line-height: 51px;
    min-width: 175px;
    height: 50px;
    letter-spacing: 0.30px;
	border-radius: 2px;
	margin: 25px auto;
}

.cap_btn {
	display:flex !important;
	text-align: center;
}

.cap_btn>div>div>div {
	width: 100% !important;
}

.footer {
	background:#232323;
	padding:20px 0px;
}

.footer-head {
	text-align:center;
	color:#fff;
	margin-top:10px;
}

.footer-head ul li{
	height: 40px;
    width: 40px;
    text-align: center;
    border: solid 1px #3bceaf;
    background: #3bceaf;
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    line-height: 2.3;
    font-size: 18px;
    margin: 0px 10px;
    margin-top: 10px;
}

.footer-head p {
	padding-top:25px;
}

/*home*/

.home {
	background:url(../img/banner.png) no-repeat center;
	background-size:cover;
	min-height:400px;
}

.home-head h1 {
	color:#fff;
	padding-top:70px;
}

.home-head p {
	color:#fff;
}

.home-head a {
	-webkit-box-shadow: -5px 10px 30px -5px rgba(0, 0, 0, 0.2);
    box-shadow: -5px 10px 30px -5px rgba(0, 0, 0, 0.2);
    background: #3bceaf;
    color: #ffffff;
	cursor: pointer;
    display: inline-block;
    position: relative;
    z-index: 2;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    padding: 0 5px;
    line-height: 51px;
    min-width: 175px;
    height: 50px;
    letter-spacing: 0.30px;
    border-radius: 2px;
}

.home-vid {
	margin-top:70px;
}

.play-button {
	position:relative;
}

.play-buttonn {
	position:absolute;
	top: -50px;
    left: 35%
}


.what {
	padding-bottom:100px;
}

.whattt {
	padding:50px 0px;
}

.what-head h1 {
	text-align:center;
	position:relative;
}

.what-head h1::after {
	content:'';
	width:100px;
	color:#3bceaf;
	position:absolute;
	bottom:-15px;
	border-bottom:solid 3px;
	left:45%;
}

.what-head h1 span {
	color:#666;
	font-size:14px;
}

.shapee {
	background:#f5f5fb;
	border:solid 1px #f5f5fb;
	height: 280px;
}

.Product {
	margin-top:50px;
	background:url(../img/bg-3.jpeg) no-repeat center;
	background-attachment:fixed;
	background-size:cover;
	padding:30px 0px;
	position: relative;
}

.Product .overlay {
    background: rgba(35, 35, 35, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.pro-head p {
	color:#fff;
	font-size:24px;
	text-align:center;
}

.pro-head h4 {
	text-align:center;
	padding-top:30px;
	color:#fff;
	position:relative;
}

.pro-head h4::before {
	content: '';
    width: 150px;
    color: #3bceaf;
    position: absolute;
    bottom: 12px;
    border-bottom: solid 3px;
    right: 25%;
}


.pro-head h4::after {
	content: '';
    width: 150px;
    color: #3bceaf;
    position: absolute;
    bottom: 12px;
    border-bottom: solid 3px;
    left: 25%;
}


.card {
	padding:20px;
	margin-top:20px;
	text-align:center;
	background: #f5f5f5;
    border: solid 1px #ccc;
}

.card p {
	padding:15px 0px;
}

.card i {
	font-size:36px;
	color:#3bceaf;
	padding-bottom:20px;
}


.crd-bt {
	text-align:center;
}

.crd-bt a {
	background:#3bceaf;
	border:solid 1px #3bceaf;
	border-radius:3px;
	padding:10px 30px;
	color:#fff;
	text-decoration:none;
}

.Monthly {
	padding:100px 0px;
	background:#f5f5fb;
}

.monthly-head h2 {
	text-align:center;
	color:#333;
}

.month-lft {
	margin-top:50px;
}

.month-lft h4 {
	font-size:22px;
}

.month-lft ul li{
	list-style:none;
	padding:10px 0px;
	font-size:15px;
}

.month-ryt {
	margin-top:50px;
	padding-left: 100px;
}

.month-ryt img {
	display:block;
}

.month-ryt input {
	outline: 0;
	margin-top:30px;
    resize: none;
    font-weight: 400;
    width: 75%;
    line-height: 24px;
    height: 49px;
    font-size: 14px;
    border: 1px solid #ccc;
    padding: 13px 20px;
    background: #fff;
    color: #323232;
    border-radius: 20px;
}

.month-ryt a {
	background: #3bceaf;
    border: solid 1px #3bceaf;
	color:#fff;
	 padding: 15px 20px;
	 margin:0px -33px;
	 border-top-right-radius: 20px;
border-bottom-right-radius: 20px;
}

/*about*/

.about {
	background:url(https://www.vyosystech.com/images/about_us.jpg) no-repeat center;
	min-height:400px;
	background-size:cover;
}

.about-cnt {
	margin-top:70px;
}

.about-head h1 {
	text-align:center;
	font-size:32px;
	color:#333;
}

.about-head p {
	text-align:center;
	padding-top:20px;
	color:#666;
}

.about-lft ul {
	margin-top:30px;
}

.about-lft ul li {
	list-style:none;
    border: solid 1px #ccc;
	padding:20px;
	border-radius:10px;
	margin:20px 0px;
	-webkit-transition: .3s;
}

.about-lft ul li:hover {
	box-shadow: 0 0 5px #B0B0B0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: all 0.3s ease 0s;
    background: #fff;
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    cursor: pointer;
}

.spn-one {
	width:10%;
	float:left;
}


.spn-two {
	width:85%;
	float:right;
}

.spn-two h3 {
	color:#333;
}

.spn-one img {
    border: solid 1px #3bceaf;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    padding: 10px;
}

.spn-two p {
	color:#666;
	padding:10px 0px;
}

.spn-two a {
	background: #3bceaf;
    border: solid 1px #3bceaf;
    border-radius: 3px;
    padding: 10px 30px;
    color: #fff;
    text-decoration: none;
}

.about-ryt {
	padding-top:130px;
}

.howitwork {
	padding:100px 0px;
}

.how-head {
	padding-left:70px;
}

.how-head h1 {
	text-align:center;
	color:#333;
	position:relative;
	padding-bottom:20px;
}

.how-head h1::after {
	content: '';
    width: 85px;
    color: #3bceaf;
    position: absolute;
    bottom: 10px;
    border-bottom: solid 3px;
    left: 40%;
}

.how-head p {
	color: #666;
    line-height: 2;
    text-align: justify;
    font-size: 16px;
}

/*browse*/

.browse {
	padding:50px 0px;
	background:#f5f5fb;
}

.browse-lft {
	padding:20px;
}

.browse-lft h1 {
	color:#333;
	font-size:32px;
}

.browse-lft p {
	color:#666;
	font-size:16px;
	font-weight:300;
}
.browsewidth{
	max-width: 1180px;
	margin:auto;
}

.search-btn{
    background : #3bceaf !important;
    color: white !important;
}
.search-input{
    height: 80% !important;
}
.search-btn:hover {
    background: #fa9343 !important;
}
.browse-ryt {
	text-align:center;
	padding:20px;
	border-radius:3px;
	background:#fff;
	transition: all 0.3s ease 0s;
	box-shadow: 0 0 5px #B0B0B0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: all 0.3s ease 0s;
}

.browse-ryt h4 {
	color:#333;
	border-bottom:solid 1px #ccc;
	padding-bottom:10px;
}

.browse-ryt p {
	color:#666;
	font-size:16px;
	font-weight:300;
}

.browse-ryt ul li {
	list-style:none;
	display:inline-block;
	padding:0px 10px;
}

.browse-ryt ul li i {
	font-size: 20px;
    color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    padding: 14px 0px;
}

.twitter {
	background:rgb(0, 172, 237);
	border:solid 1px rgb(0, 172, 237);
	cursor:pointer;
	transition:.3s;
}

.twitter:hover {
	background:#3bceaf;
	border:solid 1px #3bceaf;
	cursor:pointer;
	transition:.3s;
}

.linkedin {
	background:rgb(0, 127, 177);
	border:solid 1px rgb(0, 127, 177);
	cursor:pointer;
	transition:.3s;
}

.linkedin:hover {
	background:#3bceaf;
	border:solid 1px #3bceaf;
	cursor:pointer;
	transition:.3s;
}

.google {
	background:rgb(221, 75, 57);
	border:solid 1px rgb(221, 75, 57);
	cursor:pointer;
	transition:.3s;
}

.google:hover {
	background:#3bceaf;
	border:solid 1px #3bceaf;
	cursor:pointer;
	transition:.3s;
}


.database-tble {
	padding:20px 0px;
	padding-bottom:50px;
}

.database-tble h5 {
	color:#333;
	font-size:26px;
	border-bottom:solid 1px #ccc;
	padding-bottom:10px;
}

.database-tble th {
	background:#3bceaf;
	color:#fff;
}

.table-responsive {
	display:inline-table;
}

.page-item.active .page-link {
	background:#232323;
	color:#fff;
	border:solid 1px #232323;
}

.page-link {
	background:#3bceaf;
	color:#fff;
}

.pagination {
	float:right;
}

/*detail*/

.detail {
	background:url(../img/detail.jpg) no-repeat center;
	background-attachment:fixed;
	min-height:550px;
	background-size:cover;
	color:#fff;
}

.detail-head h1 {
	font-size:36px;
	padding-top:180px;
}

.detail-head p {
	font-size:16px;
	font-weight:300;
	padding:10px 0px;
}

.detail-head h5 {
	border-bottom:solid 1px;
	padding-bottom:25px;
}

/*feed*/

.feed {
	padding:50px 0px;
}


.feed-lyt h1 {
	font-size:32px;
	color:#333;
	border-bottom:solid 1px #ccc;
	padding-bottom:10px;
}

.feed-lyt p {
	font-size:16px;
	font-weight:300;
	color:#666;
	padding:15px 0px;
}

.feed-ryt {
	padding-top:20px;
}

.timeline button {
	margin-top:20px;
	background: #3bceaf;
    border: solid 1px #3bceaf;
}

.timeline input {
	outline: 0;
    margin-top: 30px;
    resize: none;
    font-weight: 400;
    width: 75%;
    line-height: 24px;
    height: 48px;
    font-size: 14px;
    border: 1px solid #ccc;
    padding: 13px 20px;
    background: #fff;
    color: #323232;
    border-radius: 20px;
}

.timeline a {
	background: #3bceaf;
    border: solid 1px #3bceaf;
    color: #fff;
    padding: 15px 20px;
    margin: 0px -33px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.main-timeline{
    font-family: 'Roboto Condensed', sans-serif;
    position: relative;
	padding-top:30px;
}
.main-timeline:before{
    content: '';
    background-color: #555;
    width: 4px;
    height: calc(100% - 70px);
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    bottom: 0;
}
.main-timeline:after{
    content: '';
    display: block;
    clear: both;
}
.main-timeline .timeline{
    float: left;
    width: 50%;
    padding: 0 30px;
    margin: 65px 10px 25px 0;
    position: relative;
}
.main-timeline .timeline:before{
    content: '';
    background-color: #D5223D;
    height: 33px;
    width: 33px;
    border-radius: 50%;
    box-shadow: 0 0 5px #999 inset,0 0 0 5px #dbdbdb, 0 0 13px #000;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: -17px;
}
.main-timeline .timeline-content{
    color: #555;
    background: linear-gradient(45deg,#DFDFDF,#FEFEFE);
    text-align: center;
    padding: 30px 40px;
    border-radius: 30px;
    display: block;
    position: relative;
    z-index: 1;
}
.cursor:hover{
	cursor: pointer;
}
.main-timeline .timeline-content:hover{ text-decoration: none; }
.main-timeline .timeline-content:before{
    content: '';
    background: linear-gradient(45deg,#FEFEFE,#DFDFDF);
    border-radius: 30px;
    position: absolute;
    left: 5px;
    top: 5px;
    bottom: 5px;
    right: 5px;
    z-index: -1;
}
.main-timeline .timeline-icon{
    color: #fff;
    background: linear-gradient(125deg, #ED354D 39%, #D92140 40%);
    font-size: 37px;
    text-align: left;
    line-height: 120px;
    height: 120px;
    width: 110px;
    padding-left: 15px;
    position: absolute;
    top: -56px;
    left: -30px;
    z-index: 1;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 50%);
    clip-path: polygon(0 0, 0 100%, 100% 50%);
}
.main-timeline .timeline-icon:before,
.main-timeline .timeline-icon:after{
    content: "";
    background-color: #fff;
    position: absolute;
    left: 3px;
    bottom: 6px;
    top: 6px;
    right: 6px;
    z-index: -1;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 50%);
    clip-path: polygon(0 0, 0 100%, 100% 50%);
}
.main-timeline .timeline-icon:after{
    background: linear-gradient(125deg, #ED354D 39%, #D92140 40%);
    left: 6px;
    top: 11px;
    bottom: 11px;
    right: 11px;
}
.main-timeline .title{
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 15px;
    margin: 0 0 10px;
}
.main-timeline .description{
    font-size: 13px;
    letter-spacing: 1px;
    margin: 0;
}
.main-timeline .timeline:nth-child(even){
    float: right;
    margin: 65px 0 25px 10px;
}
.main-timeline .timeline:nth-child(even):before{
    right: auto;
    left: -17px;
}
.main-timeline .timeline:nth-child(even) .timeline-icon{
    transform: rotateY(180deg);
    left: auto;
    right: -30px;
}
.main-timeline .timeline:nth-child(4n+2):before{ background-color: #96BF33; }
.main-timeline .timeline:nth-child(4n+2) .timeline-icon,
.main-timeline .timeline:nth-child(4n+2) .timeline-icon:after{
    background: linear-gradient(125deg, #A7CD43 39%, #96BF33 40%);
}
.main-timeline .timeline:nth-child(4n+3):before{ background-color: #15BEC8; }
.main-timeline .timeline:nth-child(4n+3) .timeline-icon,
.main-timeline .timeline:nth-child(4n+3) .timeline-icon:after{
    background: linear-gradient(125deg, #54B7C0 39%, #15BEC8 40%);
}
.main-timeline .timeline:nth-child(4n+4):before{ background-color: #3C21BB; }
.main-timeline .timeline:nth-child(4n+4) .timeline-icon,
.main-timeline .timeline:nth-child(4n+4) .timeline-icon:after{
    background: linear-gradient(125deg, #5436D0 39%, #3C21BB 40%);
}
@media screen and (max-width:767px){
    .main-timeline:before{
        left: 15px;
        transform: translateX(0);
    }
    .main-timeline .timeline,
    .main-timeline .timeline:nth-child(even){
        width: 100%;
        padding: 0 0 0 30px;
        margin: 65px 0 0 0;
    }
    .main-timeline .timeline:before,
    .main-timeline .timeline:nth-child(even):before{
        height: 20px;
        width: 20px;
        left: 5px;
        right: auto;
    }
    .main-timeline .timeline:nth-child(even) .timeline-icon,
    .main-timeline .timeline-icon{
        transform: translateX(-50%);
        left: 50%;
        top: -54px;
    }
    .main-timeline .timeline-content{ padding: 80px 20px 20px; }
}
@media screen and (max-width:567px){
    .main-timeline .title{ font-size: 18px; }
}

/*responsive*/

@media only screen and (max-width: 1200px) {
	
	.pro-head h4::before {
		display:none;
	}
	
	.pro-head h4::after {
		display:none;
	}
	
	.shapee {
		height:auto;
	}
	
	.contact-frm input {
		width:47%;
	}

	.cap_btn{
		display: block !important;
	}
	
}

@media only screen and (max-width: 991px) {
	
	.menu ul li a {
		text-align:center;
	}
	
	.menu img {
		width:100%;
	}
	
	.sticky img {
		width:100% !important;
	}
	
	.home-head h1 {
		font-size:26px;
	}
	
	.month-ryt {
		padding-left:0;
	}
	
	.contact-frm input {
		width:100%
	}
	
	.feed-lyt h1 {
		font-size:26px;
	}
	
	.table-responsive {
	display:block;
	}
	
	.browse-lft h1 {
		font-size:26px;
	}
	
	.browse-ryt h4 {
		font-size:24px;
	}
	
	.detail-head h1 {
		font-size:26px;
	}
	
	.menu ul li a {
		margin:5px 0px;
	}
	
}

@media only screen and (max-width: 767px) {
	
	.ryt-top-icn {
		text-align:center;
	}
	
	.left-top-icn {
		text-align:center;
		padding-top:10px;
	}
	
	.home-head h1 {
		padding-top:0;
	}
	
	.home {
		height:auto;
		padding:20px 0px;
	}
	
	.what-head h1::after {
		left: 39%;
	}
	
	.pro-head p {
		font-size:20px;
	}
	
	.card h2 {
		font-size:24px;
	}
	
	.how-head {
		padding-left:0;
		padding-top:15px;
	}
	
	.about-ryt {
		padding-top:10px;
	}
	
	.about-head h1 {
		font-size:22px;
	}
	
	.about-head p br {
		display:none;
	}
	
	.howitwork {
		padding:50px 0px;
	}
	
	.how-head h1 {
		font-size:22px;
	}
	
	.spn-two h3 {
		font-size:22px;
	}
	
	.contact-head h1 {
		font-size:36px;
	}
	
	.contact-head p {
		font-size:20px;
	}
	
	.shape {
		margin-top:0;
	}
	
	.contact-frm {
		margin-top:15px;
	}
	
	.what-head h1 {
		margin-bottom:50px;
	}
	
	.feed-lyt h1 {
		font-size:24px;
	}
	
	.browse-lft h1 {
		font-size:22px;
	}
	
	.browse-ryt h4 {
		font-size:20px;
	}
	
	.pagination {
		float:left;
		margin-top:10px;
	}
	
	.detail-head h1 {
		font-size:24px;
	}
	
}

@media only screen and (max-width: 500px) {
	
	.home-head h1 {
		font-size:22px;
	}
	
	.what-head h1 {
		font-size:22px;
		margin-bottom:15px;
	}
	
	.shape {
		margin-top:15px;
	}
	
	.pro-head p {
		font-size:18px;
	}
	
	.monthly-head h2 {
		font-size:22px;
	}
	
	.month-lft h4 {
		font-size:20px;
	}
	
	.Monthly {
		padding:50px 0px;
	}
	
	.footer-head h4 {
		font-size:16px;
	}
	
	.footer-head ul li {
    height: 25px;
    width: 25px;
    font-size: 12px;
	line-height:2;
	}
	
	.how-head p {
		line-height:inherit;
	}
	
	.about-cnt {
		margin-top:30px;
	}
	
	.spn-two {
		width:78%;
	}
	
	.frm-cnt p {
		font-size:16px;
		padding:25px 0px;
	}
	
}